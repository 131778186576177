import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const EnergyTrade = () => {
  const headingbanner = {
    title: `Energy Trade`,
    content: `An Energy Trading Platform`,
  };

  const data = {
    images: ["energy-trade.webp", "energy-trade-2.webp"],
    firstAlt: "Order Summary UI screen for Energy Trading",
    firstTitle: "Order Summary",
    secondAlt: "Screen to watch market status for trading energy",
    secondTitle: "Market Watch",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["Enterprise"],
      },
      {
        title: "What we did",
        text: ["UX Consultation", "UI UX Designing", "Design Audit"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `The existing platform did not offer a comprehensive solution that was required by a trading platform. Being, an energy trading platform, they needed an interface that can monitor and analyse the real-time data of the markets. `,
          `Hence, our primary challenge was to present the real-time data intuitively with proper tabulation by optimizing the dashboard.`,
          `The platform needed an upgradation that can simplify the existing dashboards and show the market trends in the proper tabulation of data.`,
          `We also had the challenge to cluster the information and present the bid details intuitively so that it is understandable to the users. `,
        ],
      },
      {
        title: "Solution",
        content: [
          `With our solutions, we improved the usability and interface design of the platform to provide an innovative and credible solution that can transform the Indian power markets.`,
          `Our main goal was to create an intuitive dashboard by simplifying the trading of electricity units.`,
          `We also kept the design user-friendly and functions optimized for fast keyword accessibility and simplified the buying & selling of electricity units for intraday & other markets by removing clutter.`,
        ],
      },
    ],
    image: ["energy-trade-problem.webp"],
    firstAlt: 'All Mockup Screens',
    firstTitle: 'Project Screens',
  };

  const projectData = {
    title: "Trading- Order Summary",
    para: [
      `An overview platform of the purchase and selling process of the energy units. This platform gives complete information with respect to the energy units and the balance left in the account for better understanding.`,
    ],
    content: {
      imageAlt: 'Order Summary UI screen for Energy Trading',
      imageTitle: 'Order Summary',
      image: ["energy-trade-projectone.webp"],
      text: `It also allows to bid for the entry by specifying the details of the product along with other information such as slot time, price etc. which facilitates better customization.`,
    },
    content2: {
      imageAlt: 'UI screen to add bid entry',
      imageTitle: 'Bid Entry',
      image: ["energy-trade-projectone-2.webp"],
      text: `Overall, this interface allows for personalised bidding while also keeping watch on the market price of energy units. `,
    },
  };

  const projectData2 = {
    title: `Trading- Market Watch`,
    para: [
      `This interface gives extensive information on all the products being traded in the market with their price, quantity, etc. We also added a search option for better user experience and enhanced engagements.`,
    ],
    content: {
      image: ["energy-trade-projecttwo.webp"],
      imageAlt: 'Check market status of different product',
      imageTitle: 'Market Watch',
    },
  };

  const projectData3 = {
    title: "Order Confirmation",
    para: [
      `This screen shows the order summary of the product you want to purchase along with other details for improved clarity. We also added a final confirmation order screen for a smooth buying experience.`,
    ],
    content: {
      image: [
        "energy-trade-projectthree.webp",
        "energy-trade-projectthree-2.webp",
        "energy-trade-projectthree-3.webp",
      ],
      firstAlt: 'Check order summary and confirm the order',
      firstTitle: 'Order Confirmation',
      secondAlt: 'Check bid entry to confirm order',
      secondTitle: 'Select Bid Entry',
      thirdAlt: 'UI screen to add bid entry',
      thirdTitle: 'Bid Entry',
    },
  };

  const conclusionData = [
    {
      para: [
        `Being an energy trading platform, they needed an interface to track, monitor and analyse the live data of energy units. However, their platform was not upgraded with the latest features to offer the required details. `,
        `We provided them with an extensive platform that can monitor and analyse the live data to improve the user experience and usability of the platform. `,
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={28} />
      </div>
    </Layout>
  );
};

export default EnergyTrade;

export const Head = () => (
  <Seo title="Energy Trade - Octet Design Studio" description="We did UX consultation, design audit and UI UX designing for an energy trading platform. We solved the problem of reflecting real-time data intuitively." />
)